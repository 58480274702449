class Tracker {
  constructor() {
    this.KETCH_CODES = {
      ADVERTISING: 'behavioral_advertising',
    }
    this.CUSTOM_CONSENT_CODE = '__custom'

    // the consent depends on the Consent Management Platform (CMP) used (Ketch by default, or custom in GTM container)
    this.CONSENT_CODE = this.KETCH_CODES.ADVERTISING
    if (player.data.gtmId && player.data.useMediaExternalConsent)
      this.CONSENT_CODE = this.CUSTOM_CONSENT_CODE

    this.hasDroppedLiveRampPixel = false;

    if (player.data.gtmId) {
      this.gtmIdPresent = true;
      if (player.data.useMediaExternalConsent)
        this._loadGTM(player.data.gtmId);
    }

    // decode tracking plact actions' url/script values
    if (player.data.trkActions || player.data.gtmId || player.data.isLiveRampEnabled) {
      const trackingActions = player.data.trkActions || [];

      trackingActions.forEach(a => {
        a.value = decodeURIComponent(a.value)
      })
      // stores trackers' state
      this.consentMap = this._buildConsentMap(
        trackingActions,
        player.data.gtmId,
        player.data.isLiveRampEnabled)
    }

    this._seen = new Set() // events seen
    this.consentLevel = -1 // 0: required cookie rejected, 1: required cookie accepted
    this.consent_values = null
  }

  // drops pixels & runs custom tracking from tracking plan
  // action: event name
  // data: object, additional info
  // targets: bitmask,
  //    1. send to trk host,
  //    2. send to datadog
  //    3. send to both
  track(action, data = {}, targets = TARGET_TRK) {
    try {
      performance.measure(`trk-ts-${action}`, 'origin')
      const payload = this._getTrackingParam(action, data) // get parameters object

      const { rfr, rfr2, ...qsParams } = payload

      // tech log is not for custom tracking
      // only perform client-defined tracking, if a gtmId is not present
      if (!data.log) {
        if (this.gtmIdPresent) {
          this._gtmTracking(action, data);
        } else {
          this._customTracking(action, data);
        }
      }

      let queryString = Object.entries(qsParams)
      // remove empty/null/undefined values
        .filter(([_k, v]) => v != null && v !== '')
        .map(([k, v]) => `${k}=${v}`).join('&') // and convert it to a query string

      if (rfr2) queryString += `&rfr2=${rfr2}`
      if (rfr) queryString += `&rfr=${rfr}`

      // only events marked as log are sent to datadog.
      if (data.log || targets & TARGET_LOG) {
        const url = `https://trk-go.swaven.com/log/${action}?${queryString}`

        if (navigator.sendBeacon)
          navigator.sendBeacon(url)
        else
          _addPixel(url)
      }

      // others are our core analytics data, sent to cloudfront.
      if (targets & TARGET_TRK) {
        if (navigator.sendBeacon && action === 'buy')
          navigator.sendBeacon(`${player.data.trkHost}/post?action=${action}&${queryString}&beacon=1`)

        // pixel tracking for all actions. 'buy' renamed to 'buypx'
        const pxAction = action.replace('buy', 'buypx')

        _addPixel(`${player.data.trkHost}/px.png?action=${pxAction}&${queryString}`)
      }
    }
    catch (ex) {
      console.error('Tracking error !', ex) // eslint-disable-line no-console
    }
  }

  // accept/refuse Ketch consent
  // consentObj: (object, {purposes: {[purpose]: boolean}, vendors: []}) object returned from Ketch
  // auto: (boolean) is event the initial consent event that would be triggered automatically
  setKetchConsent(consentObj, auto) {
    // only consent if adversiting, other types of consent are ignored for Ketch consent implementation
    const accepted = !!consentObj.purposes[this.KETCH_CODES.ADVERTISING]

    this.setConsent(accepted, auto)
  }

  // accept/refuse trackers
  // accept: (boolean) whether tracker is accepted
  // auto: (boolean) is event the initial consent event that would be triggered automatically
  setConsent(accepted, auto) {
    const data = this.consentMap && this.consentMap.get(this.CONSENT_CODE)

    if (!data) {
      const msg = `No data for purpose ${this.CONSENT_CODE} in consentMap`

      console.error(msg) // eslint-disable-line no-console

      // Tracker.log('error', {msg})
      return
    }

    data.accepted = !!accepted
    this.consentLevel = +data.accepted
    this.consent_values = `${this.CONSENT_CODE}=${+data.accepted}`

    this.track('consent', { consent_value: +data.accepted, consent_name: this.CONSENT_CODE, auto: +!!auto })

    if (data.accepted && data.queue.length) {
      while (data.queue.length) {
        if (this.gtmIdPresent) {
          this._sendGTMEvent(data.queue.shift())
        } else {
          this._dropServerTracking(...data.queue.shift())
        }
      }
    }

    // only add the GTM container if the user has accepted required cookies
    // AND a GTM id exists AND the experience has not opted out of GTM tracking
    if (data.accepted && player.data.gtmId) {
      this._loadGTM(player.data.gtmId)
    }

    // only add the LiveRamp pixel if the user has accepted required cookies
    // AND the subaccount has LiveRamp enabled
    if (data.accepted
      && player.data.isLiveRampEnabled
      && !this.hasDroppedLiveRampPixel) {
      this._dropLiveRampPixel();
    }
  }

  // builds tracking payload
  _getTrackingParam(action, payload = {}) {
    // extract payload data we don't need as-is in tracking
    const { avail, ...data } = payload

    const params = Object.assign({}, player.baseTrk, data, {
      ct_context: player.store && player.store.location.country || player.data.country,
      v: Date.now(),
      ts: Math.round(performance.getEntriesByName(`trk-ts-${action}`, 'measure').pop().duration),
    })

    if (params.auto != null)
      params.auto = +!!params.auto
    if (params.outbound != null)
      params.outbound = +!!params.outbound

    if (player.trkData) {
      if (player.trkData.avgpc != null)
        params.avgpc = player.trkData.avgpc
      if (player.trkData.avgdst != null)
        params.avgdist = player.trkData.avgdst
      if (player.trkData.nbres != null)
        params.nbres = player.trkData.nbres
      if (player.trkData.nbrefret != null)
        params.nbrefret = player.trkData.nbrefret
      if (player.trkData.avreqid)
        params.avreqid = player.trkData.avreqid
    }

    // if (app.error === 'UNAVAILABLE_COUNTRY_ERROR')
    //   params.oos = 1

    const availParams = this._getAvailParams(avail)

    if (action !== 'pv' && action !== 'dploy' && player._store.product)
      params.pid = player._store.product.ean

    // Add geo regional information
    if (player.store && player.store.location) {
      params.uloc_country = player.store.location.country
      params.uloc_region = player.store.location.region
      params.uloc_latitude = Math.round(player.store.location.lat * 100) / 100
      params.uloc_longitude = Math.round(player.store.location.lng * 100) / 100
      params.uloc_source = player.store.location.origin
      params.uloc_zip_code = player.store.location.zipCode
    }

    switch (action) {
      case 'buy':
        if (avail) {
          if (avail.clkUrlAffiliate)
            params.clkurlaff_prgid = avail.retailer.affiliatePrgId
          params.clkurlaff = +!!avail.clkUrlAffiliate
          params.clkurlt = avail.clkUrlType
        }
        break
        //   case 'geoloc':
        //     params.auto = 0 // geoloc event is always triggered by a user action
        //     break

      case 'clkloc':
        params.clkloc_type = data.clkloc_type || 'map'
        break
      case 'clk':
        params.autoSel = +!!data.autoSel
        break
      case 'apires':
        params.sids = player._store.avails.slice(0, 20).map(x => x.store.id)
        params.auto = 1
        break
        //   case 'close':
        //     params.auto = +!!data.auto
        //     break
      case 'unavlbsids':
        params.auto = 1
        break
      case 'clicktag':
        params.url = data.url
        params.trigger = data.trigger
        params.swavenDomain = +(/\/\/[^\/]+?\bswaven\b/i.test(data.url)) // eslint-disable-line no-useless-escape
        break
    }

    if (avail && (action === 'buy' || action === 'appointment' || action === 'clkloc')) {
      Object.entries(availParams).forEach(([k, v]) => params[k] = v)
    }

    // init params only send when necessary
    if (['apires', 'api-avails', 'storeDisplayed'].includes(action)) {
      params.init = +!this._seen.has(action)
      if (params.init)
        this._seen.add(action)
    }

    // consent action is sent for each tracker. So we cannot send an accurate consent status
    // on such action, as it may be incomplete. Just don't add the params for the action.
    if (action !== 'consent' && this.consentLevel !== -1) {
      params.consent = this.consentLevel
      params.consent_values = this.consent_values
    }

    if (player.data.hasVouchers && player._store.avails.length > 0)
      params.has_voucher = +player._store.avails.some(x => x.voucher)

    const isVchrEvt = ['buy', 'clkloc', 'appointment', 'view_voucher', 'copy_voucher'].includes(action)
                      || action.startsWith('form_')

    // track voucher details for relevant actions
    if (player.data.hasVouchers
        && player._store.currentAvail && player._store.currentAvail.voucher
        && isVchrEvt
    ) {

      params.voucher_id = player._store.currentAvail.voucher.id
      params.voucher_version_id = player._store.currentAvail.voucher.versionId

      if (player._store.currentAvail.voucherNewPrice)
        params.voucher_new_price = player._store.currentAvail.voucherNewPrice
    }

    if (payload instanceof Error) {
      params.msg = encodeURIComponent(payload.message)
      params.stack = encodeURIComponent(payload.stack)
    }

    return params
  }

  // extracts key tracking params from an availability
  _getAvailParams(avail) {
    if (!avail)
      return null

    return {
      prc: avail.price.amount,
      dist: avail.store.distance,
      dlv: this._getDlvParam(avail.store),
      sid: avail.store.id,
      sids: player._store.avails.slice(0, 20).map(x => x.store.id),
      avpid: avail.product.ean,
    }
  }

  _gtmTracking(action, data) {
    // if the action does not have a corresponding GTM event
    // or it is the clk event generated on load
    // return early

    const payload = this._structureGTMEventPayload(action, data)

    if (!payload?.event) return

    const gtmTrackingAllowed = this.gtmScriptAppended && this.consentMap.get(this.CONSENT_CODE).accepted; // eslint-disable-line max-len

    if (gtmTrackingAllowed) {
      // if the gtm container has been added to the page, we know the user gave permission
      // so we can directly send the event
      // this._sendGTMEvent(eventData);
      this._sendGTMEvent(payload);
    } else {
      // if a gtmId is present and the action has a corresponding gtm event, BUT the container
      // has not been appended yet, add the action and its data to the consentMap
      // this will be sent when the gtm container has been loaded to the page
      // and this can be contingent on user consent
      this.consentMap.get(this.CONSENT_CODE).queue.push(payload);
    }
  }

  // performs client-defined tracking
  _customTracking(action, data) {
    // build tracking payload
    const customTrkData = this._getCustomTrkData(action, data)

    // checks tracking plans for relevant actions
    if (player.data.trkActions) {

      // API-3358: clkloc events must trigger "sub-events" in tracking plan, e.g. clkloc_tel or clkloc_whatsapp.
      // The 2nd value coms from the "clkloc_type" property in data object.
      // Will also work for any event with a data prop named [action]_type.
      const composedName = `${action}_type`
      const actions = player.data.trkActions.filter(x => {
        return (x.code === action || (data[composedName] && x.code === `${action}_${data[composedName]}`))
          && (
            !x.retailerId
            || (x.retailerId === (customTrkData.retailer && customTrkData.retailer.id))
          )
      })

      if (actions.length > 0)
        this._serverTracking(actions, customTrkData)
    }
  }

  // returns type of delivery for the store
  _getDlvParam(store) {
    if (store.drive || store.collect)
      return 'driveOrCollect'
    else if (store.brick)
      return 'brick'
    else if (store.homeDelivery)
      return 'HD'
    else
      return ''
  }

  // Add data for client-specified custom tracking.
  // Does not support the "use-old-naming-for-custom-tracking" option, no backward-compatible tracking.
  _getCustomTrkData(action, data = {}) {
    const trkData = {
      action: action,
      ts: Date.now(),
      wtbid: player.data.wtbid,
      fmt: 'static-player',
      type: 'awe',
      geoCountry: player.baseTrk.geocountry,
      consentId: this._getKetchId(),
      location: {
        zipCode: '',
      },
    }

    if (player.trkData && player.trkData.nbres != null)
      trkData.resultCount = player.trkData.nbres

    if (player._store.product) {
      const pdt = player._store.product

      trkData.product = {
        id: pdt.id,
        ean: pdt.ean,
        name: this.removeHTML(pdt.name),
        name2: this.removeHTML(pdt.name2),
        pkg: this.removeHTML(pdt.pkg),
        brand: pdt.brand,
      }
    }

    // for some actions, include store & retailer info
    if (['buy', 'clkloc', 'clkRoute', 'appointment'].includes(action) && data.avail != null) {
      trkData.store = {
        id: data.avail.store.id,
        name: data.avail.store.name,
        dlv: this._getDlvParam(data.avail.store),
        url: data.avail.store.url,

        location: {
          city: data.avail.store.location.city,
          zipCode: data.avail.store.location.zipCode,
          country: data.avail.store.location.country,
        },
      }

      trkData.retailer = {
        id: data.avail.retailer.id,
        name: data.avail.retailer.name,
      }

      if (trkData.product) {
        trkData.product.retailerPdtId = data.avail.product.retailerPdtId
        trkData.product.price = data.avail.price.amount
      }
    }

    return trkData
  }

  _getKetchId() {
    const cookiePairs = document.cookie.split(";");
    let consentId = null;

    for (let i = 0; i < cookiePairs.length; i++) {
      const pair = cookiePairs[i].split("=")

      if (pair[0].trim() === "_swb") {
        consentId = pair[1];
        break;
      }
    }

    return consentId;
  }

  // server-defined custom tracking: action can be a pixel tag url to insert or a script to add
  _serverTracking(actions, data) {
    const trackerState = this.consentMap?.get(this.CONSENT_CODE)
    const allowed = trackerState?.accepted

    actions.forEach(action => {
      if (typeof action.value !== 'string') {
        console.error('Server custom tracking action defined but no value, please verify configuration in database') // eslint-disable-line no-console

        return
      }

      const isUrl = action.value.startsWith('http')
      const type = isUrl ? 'url' : 'script'
      const value = this._replacePlaceholders(action.value, data, isUrl)

      // drops or queues tracking
      if (allowed)
        this._dropServerTracking(value, type)
      else
        trackerState?.queue?.push([value, type])
    })
  }

  // sends to loader for host-side tracking. If safeframe, drops pixel directly.
  _dropServerTracking(data, type) {
    switch (type) {
      case 'url':
        _addPixel(data)
        break
      case 'script':
        this._addScript(data)
        break
    }
  }

  _loadGTM(gtmId) {
    if (!gtmId || this.gtmScriptAppended) return;

    let container = gtmId;

    // replaces the GTMId in dev to our test container ID
    // this makes sure we are not diluting customer production data
    // AND gives feedback to devs when working locally
    if (!/^prod(uction)?$/.test(player.data.env)) {
      console.log('MikMak test GTM container has been added to the browser') // eslint-disable-line no-console
      const mikMakTestGtmContainer = 'GTM-K4X9LPS';

      container = mikMakTestGtmContainer;
    }

    try {
      const gtmTag = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window, document,'script','dataLayer','${container}');`
      const nd = document.createElement('script')

      nd.innerText = gtmTag
      document.head.appendChild(nd)

      this.gtmScriptAppended = true;
    } catch (err) {
      logger.error('Error adding the GTM container', err)
    }
  }

  _dropLiveRampPixel() {
    const liveRampPixelId = 712896;
    const encodedPData = encodeURIComponent(`wtbid=${player.data.wtbid}`);

    _addPixel(`https://di.rlcdn.com/api/segment?pid=${liveRampPixelId}&pdata=${encodedPData}`);

    this.hasDroppedLiveRampPixel = true;
  }

  // v1 GTM events backward compatibility
  _structureGTMEventPayloadV1(action, data) {
    const gtmEvents = {
      pv: 'mikmak_load',
      buy: 'mikmak_checkout',
      variantShelfOpen: 'mikmak_variant_shelf_shown',
      clk: 'mikmak_variant_selected',
      clktab: 'mikmak_tab',
    };

    // if the action does not have a corresponding GTM event
    // or it is the clk event generated on load
    // return early
    if (!gtmEvents[action] || (action === 'clk' && data.auto)) return;

    const trkData = {
      action: action,
      event: gtmEvents[action],
      ts: Date.now(),
      wtbid: player.data.wtbid,
      fmt: 'static-player',
      type: 'awe',
      geoCountry: player.baseTrk.geocountry,
      consentId: this._getKetchId(),
      location: {
        zipCode: '',
      },
    }

    if (player.trkData && player.trkData.nbres != null)
      trkData.resultCount = player.trkData.nbres

    if (player._store.product) {
      const pdt = player._store.product

      trkData.product = {
        id: pdt.id,
        ean: pdt.ean,
        name: this.removeHTML(pdt.name),
        name2: this.removeHTML(pdt.name2),
        pkg: this.removeHTML(pdt.pkg),
        brand: pdt.brand,
      }
    }

    // for some actions, include store & retailer info
    if (['buy', 'clkloc', 'clkRoute', 'appointment'].includes(action) && data.avail != null) {
      trkData.store = {
        id: data.avail.store.id,
        name: data.avail.store.name,
        dlv: this._getDlvParam(data.avail.store),
        url: data.avail.store.url,

        location: {
          city: data.avail.store.location.city,
          zipCode: data.avail.store.location.zipCode,
          country: data.avail.store.location.country,
        },
      }

      trkData.retailer = {
        id: data.avail.retailer.id,
        name: data.avail.retailer.name,
        sanitizedName: this.sanitizeString(data.avail.retailer.name),
      }

      if (trkData.product) {
        trkData.product.retailerPdtId = data.avail.product.retailerPdtId
        trkData.product.price = data.avail.price.amount
      }
    }

    return trkData
  }

  _structureGTMEventPayload(action, data) {

    // backward compatibility GTM event
    if (!player.data.gtmEventsV2)
      return this._structureGTMEventPayloadV1(action, data)

    const payload = {
      wtbid: player.data.wtbid,
      geo_country: player.baseTrk.geocountry,
      consent_id: this._getKetchId(),
      commerce_type: 'media',
      experience_name: player.data.sourceName,
      account_name: player.data.accountName,
      subaccount_name: player.data.operatorName,
    };

    try {
      if (action === 'clk' && data.auto) {
        payload.event = 'mikmak_load'
      } else if (action === 'buy') {
        payload.event = 'mikmak_checkout'
      } else if (action === 'clk' && !data.auto) {
        payload.event = 'mikmak_product_selected'
      } else if (action === 'variantShelfOpen') {
        payload.event = 'mikmak_variant_shelf_open'
      } else if (action === 'clkloc') {
        if (data.clkloc_type === 'tel')
          payload.event = 'mikmak_call_offline_store'
        else if (data.clkloc_type === 'whatsapp')
          payload.event = 'mikmak_whatsapp_offline_store'
        else if (data.clkloc_type === 'appointment')
          payload.event = 'mikmak_click_appointment_offline_store'
        else
          payload.event = 'mikmak_click_offline_store' // map
      } else if (action === 'geoloc') {
        payload.event = 'mikmak_update_location'
      } else if (action === 'view_voucher') {
        payload.event = 'mikmak_click_voucher'
      } else if (action === 'copy_voucher') {
        payload.event = 'mikmak_copy_voucher'
      } else if (action === 'clktab') {
        payload.event = 'mikmak_tab'
        payload.tab_clicked = data.tab
      }

      if (player._store.location) {
        payload.location_zipcode = player._store.location.zipCode || ''
        payload.location_city = player._store.location.city || ''
        payload.location_state = player._store.location.region || ''
        payload.location_country = player._store.location.country || ''
      }

      // add product info
      if (player._store.product) {
        const pdt = player._store.product

        payload.product_gtin = pdt.ean
        payload.product_name = this.removeHTML(pdt.name)
        payload.product_brand = pdt.brand || ''
        payload.product_id = pdt.sourcePid
        payload.product_package = this.removeHTML(pdt.pkg) || ''
        payload.product_model = this.removeHTML(pdt.model) || ''
        payload.product_line = this.removeHTML(pdt.productLine) || ''
        payload.product_range = this.removeHTML(pdt.range) || ''
        payload.product_category = this.removeHTML(pdt.catName) || ''

        if (pdt.name2) {
          payload.product_variant = this.removeHTML(pdt.name2)
        }
      }

      // add purchase or click specific info
      if (action === 'buy' || action === 'clkloc') {
        payload.product_price = data.avail.price.amount
        payload.product_price_currency = data.avail.price.currency

        payload.product_price_text = (new Intl.NumberFormat(`${player.data.locale}`, {
          style: 'currency',
          currency: data.avail.price.currency,
          minimumFractionDigits: 2,
        })).format(data.avail.price.amount)
        payload.retailer_id = data.avail.retailer.id
        payload.retailer_name = data.avail.retailer.name
        payload.retailer_name_sanitized = this.sanitizeString(data.avail.retailer.name)
        payload.store_id = data.avail.store.id
        payload.store_name = data.avail.store.name
        payload.store_city = data.avail.store.location.city
        payload.store_zipcode = data.avail.store.location.zipCode
      }
    } catch (e) {
      console.error('Error structuring GTM event payload', e) // eslint-disable-line no-console
    }

    return payload
  }

  _sendGTMEvent(eventPayload) {
    if (!window.dataLayer) return;

    window.dataLayer.push(eventPayload);

    // also send the 'mikmak_retailer_preference' event if the action is `buy`
    if (eventPayload.action === 'buy') {

      // also send retailer preference universal event with the same payload

      // make a copy of the payload because when the buy event is queued
      // and sent after user consent, for some reason the 'mikmak_retailer_preference' event
      // is sent twice and the buy event is never sent
      // this way there is clear differentiation between the two events/payloads
      const retailPrefPayload = {
        ... eventPayload,
        event: 'mikmak_retailer_preference',
      }

      window.dataLayer.push(retailPrefPayload);
    }
  }

  // process string (url or script) to replace placeholders (delimited by double-brackets) with the actual corresponding value
  _replacePlaceholders(str, data, encode) {
    if (data == null) // no data => nothing to replace
      return str

    // new name scheme: properties are not unique. placeholder is a path to the value (e.g. '{{store.location.country}}').
    // We decompose each placeholder into a list of keys and mine the data object key after key until we've processed to whole path.
    str = str.replace(/{{(.+?)}}/g, (match, pattern) => {
      const keys = pattern.split('.')
      let value = data

      try {
        for (let i = 0; i < keys.length; i++) {
          value = value[keys[i]]
        }

        if (value == null)
          return ''
        else
          return encode ? encodeURIComponent(value.toString()) : value.toString()
      }
      catch (ex) { // on error (e.g. accessing a null object), return the placeholder
        return match
      }
    })

    return str
  }

  // Add tracking script, either pure js or in html (multiple script tags)
  // @param {String} script : tracking script
  _addScript(script) {
    // whether script is js or html - just look at a script closing tag.
    // NOTE: we use the unicode codepoint for the opening less-than sign to not interfere
    //       with media pops created from parsed response.
    const isHTML = script.indexOf('\u003C/script>') > -1

    if (isHTML) {
      // split string into each script
      const scripts = script.match(/<script.*?<\/script>/gs)

      if (scripts && scripts.length >= 1) {
        scripts.reduce(async (prom, script, idx, list) => {
          const openingTag = script.match(/<script.*?>/)[0], // extract the opening tag
            hasExternal = openingTag.match(/src=['"](.+?)['"]/), // whether opening tag contains an src attribute
            nd = document.createElement('script')

          if (hasExternal)
            nd.src = hasExternal[1]
          else
            nd.innerHTML = script.replace(/<script.*?>/, '').replace('\u003C/script>', '')

          let p

          // if script has an external src, wait for it to load before inserting next script.
          // Only for scripts that are not the last one.
          if (hasExternal && idx < list.length - 1) {
            p = new Promise((resolve, reject) => {
              nd.addEventListener('load', resolve)
              nd.addEventListener('error', function() {
                reject('Load error for script ' + nd.src)
              })
            })
          }
          else {
            p = Promise.resolve()
          }

          await prom
          document.body.appendChild(nd)

          return p
        }, Promise.resolve())
      }
    }
    else {
      // script is pure js, wrap in try/catch and insert.
      const nd = document.createElement('script')

      nd.innerHTML = 'try{' + script + '}catch(err){console.error(err)}' // eslint-disable-line no-console
      document.body.appendChild(nd)
    }
  }

  // creates a map of Ketch consent purposes from tracking plan action: tracker name as key, status & queue as value.
  _buildConsentMap(actions, gtmIdPresent, isLiveRampEnabled) {
    const consentMap = new Map()

    if ((actions.length > 0
      || gtmIdPresent
      || isLiveRampEnabled)
      && !consentMap.has(this.CONSENT_CODE)) {
      consentMap.set(this.CONSENT_CODE, {
        accepted: null, // trackers are blocked by default, until Ketch tells us it's ok
        queue: [], // store blocked trackings, to replay later if tracker is accepted.
      })
    }

    if (consentMap.size)
      return consentMap
    else
      return null

  }

  removeHTML(input) {
    let res = input

    if (typeof input === 'string')
      res = input.replace(/\<br\s*\/?\>/gi, ' ').replace(/\<[^\>]*\>/g, '').replace(/\s{2,}/g, ' ').trim() // eslint-disable-line no-useless-escape

    return res
  }

  sanitizeString(input) {
    // remove diacritics
    // replace spaces and special characters not '-' by '_'
    return input?.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replaceAll(/[^\w-]/gi, '_').toLowerCase();
  }
}

window.tracker = new Tracker()
tracker.track('pv', { auto: true }, TARGET_ALL)

// allow or disallow MikMak event consent
function mikmakConsent(accepted) { // eslint-disable-line no-unused-vars
  window.tracker.setConsent(accepted)
}

// Basic logger as a wrapper around tracker.
class Logger {
  constructor(env) {
    this.env = env
  }
  _track(action, data) {
    data.log = true
    tracker.track(action, data, 2)
  }
  log(action, data) {
    data = data || {}
    if (!data.level) data.level = 'info'

    this._track(action, data)
    // no console output for info outside dev
    if (this.env === 'dev' || data.level !== 'info')
      console.log(action, data) // eslint-disable-line no-console
  }

  // msg is appended after error-provided messages
  error(msg, err) {
    err = err || {}
    if (msg) err.message += ': ' + msg
    err.level = 'error'
    this._track('error', err)
    console.error(err) // eslint-disable-line no-console
  }
  // log some user actions (e.g. pv, storeDisplayed), but no need to output.
  event(action, data) {
    data = data || {}
    data.level = 'info'
    this._track(action, data)
  }
}
window.logger = new Logger(player.data.env)
